import CartLinkHeader from "../cart-link-header/cart-link-header";
import request from 'oc-request';
import BNative from 'bootstrap.native/dist/bootstrap-native-v4.js';
import BSN from "bootstrap.native/dist/bootstrap-native-v4";

export default new class CartPositionList {
    constructor() {
        this.cartSelector = '.cart';
        this.orderForm = '_ajax_create_order';
        this.rowWrapper = 'details__order--item';
        this.errorClass = 'border border-danger';
        this.cartEmptySelector = '.cart__empty';
        this.cartTableSelector = '.cart-data-container';
        this.cartItemSelector = 'details__order--item';
        this.buttonRemoveItemSelector = '.cart__delete-btn';
        this.cartTableTitleSelector = '.cart__table-title';
        this.cartTotalSelector = '.details';
        this.positionPrice = 'cart__position-price-value';
        this.positionOldPrice = 'cart__position-old-price-value';
        this.positionOldPriceWrapper = 'cart__position-old-price-figures';
        this.positionTotalPrice = 'cart__total-position-price-value';
        this.positionTotalOldPrice = 'cart__total-position-old-price-value';
        this.positionTotalOldPriceCurrency = 'cart__total-position-old-price-currency';
        this.totalPrice = 'cart__total-price-price-value';
        this.totalOldPrice = 'cart__total-price-old-price-value';
        this.totalOldPriceCurrency = 'cart__total-price-old-price-currency';
        this.shippingPrice = 'cart__total-shipping-price-value';
        this.shippingOldPrice = 'cart__total-shipping-old-price-value';
        this.shippingOldPriceCurrency = 'cart__total-shipping-old-price-currency';
        this.tabs = '#myCartTab',
        this.continueBtn = '#purchase',
        this.once = false,

            this.eventHandlers();
    }

    eventHandlers()
    {
        const _this = this;

        if ( !document.querySelector(`${this.cartSelector}`) ) {
            return;
        }

        _this.setActiveFrontendShippingType();

        document.addEventListener('DOMContentLoaded', function () {

            Array.from(document.querySelectorAll("select[data-target=property_slim]")).forEach(function ( el ) {
                let name = el.name.toLowerCase();
                let value = null;
                if (document.querySelector(`input[data-prop-name=${name}]`)) {
                    value = document.querySelector(`input[data-prop-name=${name}]`).value;
                }
                let slim = new SlimSelect({
                    select: el,
                    showSearch: false,
                });
                if ( value ) {
                    slim.set(value);
                }
            })
        });

        document.addEventListener('click', (e) => {
            const target = e.target.closest('.custom-radio')
            if (target) {
                const input = target.querySelector('.form-check-input[type="radio"]'),
                    parent = target.parentNode,
                    activeShippingTypeID = input.value;

                window.history.pushState(null, null, `${location.origin}${location.pathname}?activeShipping=${activeShippingTypeID}`);

                Array.from(parent.children).forEach(function(el){
                    el.classList.remove('active');
                    el.querySelector('input[type="radio"]').removeAttribute('checked');
                })

                document.querySelector(`input[name="shipping_type_id"]`).setAttribute('value', activeShippingTypeID );
                target.classList.add('active');
                target.querySelector('input[type="radio"]').setAttribute('checked', 'checked');
                _this.sendActiveShippingType(activeShippingTypeID);
            }
        });

        document.addEventListener('click', function (e) {
            const btnRemoveItem = e.target.closest(`${_this.buttonRemoveItemSelector}`);
            if (btnRemoveItem) {
                _this.removeItemFromTable(btnRemoveItem);
                _this.sendRequestRemoveItem(btnRemoveItem);
            }
        });

        document.addEventListener('click', function (e) {
            const btnRemoveItem = e.target.closest(`.fast-cart__delete-btn`);
            if (btnRemoveItem) {
                _this.removeItemFromTable(btnRemoveItem);
                _this.sendRequestRemoveItem(btnRemoveItem);
            }
        });

    }

    removeItemFromTable($btnRemoveItem)
    {
        const _this = this,
            $cartItemToRemove = document.querySelector(`div[data-position-id='${$btnRemoveItem.getAttribute('data-position-id')}']`),
            cartItemsNumber = document.querySelector('.cart-data-container').children.length,
            $cartTable = document.querySelector(`${_this.cartTableSelector}`),
            $cartEmpty = document.querySelector(`${_this.cartEmptySelector}`),
            $cartTotal = document.querySelector(`${_this.cartTotalSelector}`),
            $btnFastAddToCart = document.querySelector(`.fast-add-to-cart[data-product-id='${$btnRemoveItem.getAttribute('data-product-id')}']`),
            $btnAddToCart = document.querySelector(`.btn-add-to-cart[data-product-id='${$btnRemoveItem.getAttribute('data-product-id')}']`),
            $btnFastCart = document.querySelector(`.btn-fast-cart`);


        if ( cartItemsNumber > 1 ) {
            $cartItemToRemove.remove();
        } else if (cartItemsNumber === 1) {
            document.querySelector('.mini-cart-backdrop').classList.add('last-item');
            $cartTable.remove();
            if ($cartEmpty)
                $cartEmpty.classList.remove('d-none');
            if ($btnFastCart)
                $btnFastCart.remove();
            $cartTotal.classList.add('d-none');
            if (document.querySelector('#cartBackBtn'))
                document.querySelector('#cartBackBtn').setAttribute('href', `/`)
        }

        if ($btnAddToCart) {
            $btnAddToCart.querySelector('p').innerHTML = `${$btnAddToCart.getAttribute('data-add-text')} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="20.5" r="1"/><circle cx="18" cy="20.5" r="1"/><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1"/></svg>`;
            $btnAddToCart.setAttribute('data-event', 'add');
        }

        if ($btnFastAddToCart) {
            $btnFastAddToCart.querySelector('p').innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="20.5" r="1"/><circle cx="18" cy="20.5" r="1"/><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1"/></svg>`;
            $btnFastAddToCart.setAttribute('data-event', 'add');
        }
    }

    sendRequestRemoveItem($btn)
    {
        const activeShippingTypeID = document.querySelector(`input[name='shipping_type_id']`);
        const itemId = $btn.getAttribute('data-position-id'),
            _this = this,
            data = {
                'cart': [itemId],
                'type': 'position',
                'shipping_type_id': activeShippingTypeID?activeShippingTypeID.getAttribute('value'):null };

        request.sendData('Cart::onRemove', {
            'data': data,
            success: function (response) {
                // CartLinkHeader.updateBlock();
                if (!!response && response.status && !!response.data) {
                    _this.updatePrice(response.data);
                }
            }
        });
    }

    sendRequestUpdateItem($btn)
    {

        const row = document.querySelector(`div[data-position-id='${$btn.getAttribute('data-position-id')}']`),
            _this = this,
            activeShippingTypeID = document.querySelector('input[name="shipping_type_id"]'),
            quantity = parseInt(row.querySelector('input[name=quantity]').getAttribute('value')),
            maxQuantity = parseInt(row.querySelector('input[name=quantity]').getAttribute('max')),
            data = {
                'cart': [
                    {
                        'offer_id': row.getAttribute('data-offer-id'),
                        'quantity': quantity }
                ],
                'shipping_type_id': activeShippingTypeID?activeShippingTypeID.getAttribute('value'):null
            };

        if(document.contains(document.querySelector(`${_this.continueBtn}`))){
            document.querySelector(`${_this.continueBtn}`).classList.add('disabled');
        }

        if (document.querySelector(`${_this.tabs}`))
            Array.from(document.querySelector(`${_this.tabs}`).children).forEach(function(el) {
                if (el.querySelectorAll('.nav-link').length > 0)
                    el.querySelectorAll('.nav-link').forEach( link => {
                        link.classList.add('disabled')
                    });
            })


        request.sendData('Cart::onUpdate', {
            'data': data,
            success: function (response) {
                if (!!response && response.status && !!response.data) {
                    _this.updatePrice(response.data);
                }
            }
        });
    }

    sendActiveShippingType(activeShippingTypeID)
    {
        const _this = this;
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-OCTOBER-REQUEST-HANDLER': 'Cart::onSetShippingType',
        }

        axios.post(
        window.location.href,
        {
            'shipping_type_id': activeShippingTypeID
        },
        {
            headers: headers
        }
        ).then((response) => {
            const { status } = response;
            const { data } = response;
            if (status == 200) {
                _this.updatePrice(data.data);
            }
        })
        .finally(()=>{
            // find all elements with title attribute
            var elementsTooltip = document.querySelectorAll('[title]');

            // attach a tooltip for each
            if (elementsTooltip) {
                Array.from(elementsTooltip).map(
                    tip => new BNative.Tooltip( tip, {
                        placement: 'top', //string
                        animation: 'slideNfade', // CSS class
                        delay: 150, // integer
                    })
                )
            }
        })
    }

    updatePrice(cartData)
    {
        const _this = this;

        if (!cartData) {
            return;
        }

        request.sendData('onAjax', {
            data: {},
            update: {
                'cart/cart-position-list/shipping-list/shipping-list':'#shipping-list',
                'cart/cart-position-list/cupons/cupons':'#cupon-container',
                'cart/cart-position-list/final-price/final-price':'#finalPrice',
                'cart/cart-position-list/fixed-bottom/fixed-bottom':'#fixedBottomMenu',
            },
            success: () => {
                CartLinkHeader.updateBlock();
                // Actualizando precios
                const positionIDList = Object.keys(cartData.position);
                if (positionIDList) {
                    positionIDList.forEach((positionID) => {
                        const priceData = cartData.position[positionID],
                            positionItem = document.querySelector(`.${this.cartItemSelector}[data-position-id="${positionID}"]`);

                        if(positionItem && document.contains(positionItem.querySelector(`.${this.positionPrice}`)))
                            positionItem.querySelector(`.${this.positionPrice}`).innerHTML = priceData.price_value;

                        if(document.contains(positionItem && positionItem.querySelector(`.${this.positionOldPrice}`)))
                            positionItem.querySelector(`.${this.positionOldPrice}`).innerHTML = priceData.old_price;

                        if (positionItem && priceData.discount_price_value == 0 && document.contains(positionItem.querySelector(`.${this.positionOldPriceWrapper}`))) {
                            positionItem.querySelector(`.${this.positionOldPriceWrapper}`).classList.add('d-none');
                        } else {
                            if(positionItem && document.contains(positionItem.querySelector(`.${this.positionOldPriceWrapper}`)))
                                positionItem.querySelector(`.${this.positionOldPriceWrapper}`).classList.remove('d-none');
                        }
                    });
                }

                if(document.contains(document.querySelector(`.${this.positionTotalPrice}`)))
                    document.querySelector(`.${this.positionTotalPrice}`).innerHTML = cartData.position_total_price.price;

                if(document.contains(document.querySelector(`.${this.positionTotalOldPrice}`)))
                    document.querySelector(`.${this.positionTotalOldPrice}`).innerHTML = cartData.position_total_price.old_price;


                if (cartData.position_total_price.discount_price_value == 0 ) {
                    if(document.querySelector(`.${this.positionTotalOldPrice}`))
                        document.querySelector(`.${this.positionTotalOldPrice}`).classList.add('d-none');

                    if(document.contains(document.querySelector(`.${this.positionTotalOldPriceCurrency}`)))
                        document.querySelector(`.${this.positionTotalOldPriceCurrency}`).classList.add('d-none');
                } else {
                    if(document.querySelector(`.${this.positionTotalOldPrice}`))
                        document.querySelector(`.${this.positionTotalOldPrice}`).classList.remove('d-none');

                    if(document.contains(document.querySelector(`.${this.positionTotalOldPriceCurrency}`)))
                        document.querySelector(`.${this.positionTotalOldPriceCurrency}`).classList.remove('d-none');
                }

                if(document.contains(document.querySelector(`.${this.shippingPrice}`)))
                    document.querySelector(`.${this.shippingPrice}`).innerHTML = cartData.shipping_price.price;

                if(document.contains(document.querySelector(`.${this.shippingOldPrice}`)))
                    document.querySelector(`.${this.shippingOldPrice}`).innerHTML = cartData.shipping_price.old_price;

                if (cartData.shipping_price.discount_price_value == 0) {
                    if(document.contains(document.querySelector(`.${this.shippingOldPrice}`)))
                        document.querySelector(`.${this.shippingOldPrice}`).classList.add('d-none');

                    if(document.contains(document.querySelector(`.${this.shippingOldPriceCurrency}`)))
                        document.querySelector(`.${this.shippingOldPriceCurrency}`).classList.add('d-none');
                } else {
                    if(document.contains(document.querySelector(`.${this.shippingOldPrice}`)))
                        document.querySelector(`.${this.shippingOldPrice}`).classList.remove('d-none');

                    if(document.contains(document.querySelector(`.${this.shippingOldPriceCurrency}`)))
                        document.querySelector(`.${this.shippingOldPriceCurrency}`).classList.remove('d-none');
                }

                if(document.contains(document.querySelector(`.${this.totalPrice}`)))
                    document.querySelector(`.${this.totalPrice}`).innerHTML = cartData.total_price.price;

                if(document.contains(document.querySelector(`.${this.totalOldPrice}`)))
                    document.querySelector(`.${this.totalOldPrice}`).innerHTML = cartData.total_price.old_price;

                if (cartData.total_price.discount_price_value == 0) {
                    if(document.contains(document.querySelector(`.${this.totalOldPrice}`)))
                        document.querySelector(`.${this.totalOldPrice}`).classList.add('d-none');

                    if(document.contains(document.querySelector(`.${this.totalOldPriceCurrency}`)))
                        document.querySelector(`.${this.totalOldPriceCurrency}`).classList.add('d-none');
                } else {
                    if(document.contains(document.querySelector(`.${this.totalOldPrice}`)))
                        document.querySelector(`.${this.totalOldPrice}`).classList.remove('d-none');

                    if(document.contains(document.querySelector(`.${this.totalOldPriceCurrency}`)))
                        document.querySelector(`.${this.totalOldPriceCurrency}`).classList.remove('d-none');
                }

                // document.querySelectorAll('.finalTotalPrice').forEach( span => {
                //     span.innerHTML = parseFloat(cartData.total_price.price_value);
                // })

                //Deshabilitando tabs y continueBtn
                if(document.contains(document.querySelector(`${this.continueBtn}`)))
                    document.querySelector(`${this.continueBtn}`).classList.remove('disabled');

                if (document.querySelector(`${this.tabs}`))
                    Array.from(document.querySelector(`${this.tabs}`).children).forEach(function(tabs) {
                        if (tabs.querySelectorAll('.nav-link').length > 0)
                            tabs.querySelectorAll('.nav-link').forEach( link => {
                                link.classList.remove('disabled')
                            });
                    })
            },
            complete: () => {
                let searchParams = new URLSearchParams(window.location.search);

                _this.setActiveFrontendShippingType();

                // find all elements with title attribute
                var elementsTooltip = document.querySelectorAll('[title]');

                // attach a tooltip for each
                if (elementsTooltip) {
                    Array.from(elementsTooltip).map(
                        tip => new BSN.Tooltip( tip, {
                            placement: 'top', //string
                            animation: 'slideNfade', // CSS class
                            delay: 150, // integer
                        })
                    )
                }
            }
        });
    }

    setActiveFrontendShippingType()
    {
        // const _this = this;
        let searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('activeShipping')) {
            let params = searchParams.get('activeShipping')
            document.querySelector(`.form-check-input[value="${params}"]`).setAttribute('checked', 'checked');
            document.querySelector(`input[name="shipping_type_id"]`).setAttribute('value', params );
            let parent = document.querySelector(`.form-check-input[value="${params}"]`).parentNode;
            if(parent)
                parent.classList.add('active');
            if (!this.once) {
                this.sendActiveShippingType(params)
                this.once = true;
            }
        }else{
            const _this = this;
            const headers = {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                'X-OCTOBER-REQUEST-HANDLER': 'onAjax',
            }

            axios.post(
                window.location.href,
                {},
                {
                    headers: headers
                }
            ).then((response) => {
                const { status } = response;
                const { data } = response;
                if (status == 200) {
                    _this.updatePrice(data.data);
                }
            })
                .finally(()=>{})
        }
        // else{
        //     let price_shipping = 0;
        //     let type = null;
        //     let list_of_shipping = document.querySelectorAll('.form-check-input[type="radio"]');
        //     Array.from(list_of_shipping).forEach((el) => {
        //         if (el.getAttribute('data-shipping-price') > price_shipping) {
        //             price_shipping = el.getAttribute('data-shipping-price');
        //             type = el.value;
        //         }
        //     })
        //     if (document.contains(document.querySelector(`#shipping-price-${price_shipping}`))) {
        //         document.querySelector(`#shipping-price-${price_shipping}`).setAttribute('checked', 'checked');
        //         document.querySelector(`input[name="shipping_type_id"]`).setAttribute('value', type );
        //         let parent = document.querySelector(`#shipping-price-${price_shipping}`).parentNode;
        //         if(parent)
        //             parent.classList.add('active');
        //
        //         addUrlParameter('activeShipping', type, false);
        //     }
        // }
    }

    setOnce(val)
    {
        this.once = val;
    }
}();
